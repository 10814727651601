/* #timesheet .filter_cont .search_cont{
    width: 25vw;
} */

.date-group{
    display: flex;
    align-items: center;
}

.date-group p {
    margin-right: 1rem;
    font-size: .6rem;
    font-family: Outfit-Bold;
    text-transform: uppercase;
}