.tab-btn_cont{
    display: flex;
    align-items: center;
}

.tab-btn_cont button{
    padding: .6rem 1rem;
    width: 10vw;
    font-size:  .6rem;
    font-family: Outfit-Medium;
    background-color: transparent;
    text-transform: uppercase;
    border: 1px solid var(--gray-dark) ;
}

.tab-btn_cont button:first-child{
    border-radius: .5rem 0 0 .5rem;
}
.tab-btn_cont button:last-child{
    border-radius:  0 .5rem .5rem 0;
}

.tab-btn_cont button.active{
    background-color: var(--primary_color);
    color: white;
    border: 1px solid var(--primary_color);
}