#ssettion_wraper {
    margin-top: 2rem;
}

#ssettion_wraper .profile_details_cont .header h4{
   font-size: .9rem !important;
}

#ssettion_wraper .profile_details_cont .img_cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.5rem;
}

#ssettion_wraper .profile_details_cont .header {
    align-items: self-start;
}
#ssettion_wraper .profile_details_cont .header .profile_txt{
   margin-top: 1rem;
}

.profile_details_cont .header .profile_txt h3 ,  .profile_details_cont .header .profile_txt h3 {
    text-transform: capitalize;
}

#ssettion_wraper .profile_details_cont .img_cont .profile_img_cont{
    margin: initial;
    overflow: hidden;
}
#ssettion_wraper .profile_details_cont .img_cont .profile_img_cont img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.information_cont .edit-btn{
    margin-left: 1rem;
}

.edit-btn{
    color: var(--primary_color);
    font-size: var(--font_small);
    margin-top: 1rem;
    cursor: pointer;
}

.edit-btn i{
    margin-left: .5rem;
}