.login_wraper{
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: fit-content;
    width: 38%;
    border-radius: 1.4rem;
    padding: 3rem 4rem;
}

.inp_fields_cont{
    width: 100%;
    margin-top: 2rem;
}

.inp_fields_cont .remembreme_forgote_pass{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 1rem;
}

#forgot_pass .inp_fields_cont .remembreme_forgote_pass{
    margin-bottom: initial;
}

.inp_fields_cont .remembreme_forgote_pass .check_box_cont{
    display: flex;
    align-items: center;
}

.remembreme_forgote_pass .check_box_cont label{
    font-size: var(--font_small);
    margin-left: .5rem;
    margin-bottom: .1rem;
    cursor: pointer;
}

.remembreme_forgote_pass p{
    font-size: var(--font_small);
    color: var(--primary_color);
    text-decoration: underline;
    cursor: pointer;
}

.login_form .header{
     display: flex;
     flex-direction: column;
     align-items: center;
}

.login_form .header h2{
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: Outfit-Bold;
    margin-top: 2.5rem;
}

.login_form .header img{
    width: 9rem;
}

.contact_us{
    font-size: var(--font_small);
    text-align: center;
    margin-top: 2rem;
}

.contact_us span{
    color: var(--primary_color);
    text-decoration: underline;
}