#add-subcontractor{
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 150vh;
    padding: 3rem;

}

#add-subcontractor h2{
    font-size: var(--font_para);
    font-family:  Outfit-Bold;
    margin-bottom: 3rem;
}

.first_last_name_inp{
    width: 30vw;
    display: flex;
    justify-content: space-between;
}