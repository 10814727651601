* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Outfit-Regular;
}

body,
html {
  width: 100%;
  background-color: var(--white-color);
  font-size: 1.03vw !important;
}

*::-webkit-scrollbar {
  width: .5rem;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: var(--gray-color);
}

#root {
  min-height: 100vh;
  width: 100%;
  background-color: var(--white-color);
}

/* App container css */
#app_cont {
  display: flex;
  padding: 1.4vw;
  justify-content: space-between;
}

#app_cont #sidebar_cont {
  width: 18%;
}

#app_cont #page_wraper {
  width: 79.2%;
}

a {
  text-decoration: none !important;
  color: black !important;
}

p {
  margin: initial;
}

h3 {
  font-size: var(--font_small) !important;
  font-family: Outfit-Bold;
  margin: initial;
}

.apexcharts-text {
  fill: black !important;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.err_message {
  color: red;
  text-align: center;
}

.bg-primary_color {
  background-color: var(--primary_color);
  color: white;
}

.orange_btn {
  background-color: var(--primary_color) !important;
  border-color: var(--primary_color) !important;
  color: white;
}

.btn_style {
  padding: .4rem 2rem !important;
  margin: 0 1rem !important;
}

/* Profile */
.profile_cont {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile_cont .profile-img {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--gray-dark);
  border-radius: 100%;
  margin-right: .6rem;
  overflow: hidden;
}

.profile_cont .profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile_cont .profile-name_cont {
  width: calc(100% - 3.5rem);
}

.profile_cont .profile-name_cont p:last-child {
  /* color: var(--primary_color); */
  font-family: Outfit-Medium;
}

.profile_cont .profile-name_cont p {
  margin: initial;
  text-transform: capitalize;
  font-family: Outfit-Bold;
}

/* Table Css */
.common-table {
  border-collapse: separate !important;
  border-spacing: 0 .9rem !important;
}

.common-table tbody tr {
  cursor: pointer;
}

.common-table tbody tr td a {
  padding: .2rem .8rem;
  border-radius: 100px;
  border: .1rem solid var(--gray-color);
  background-color: transparent;
  transition: all .3s;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: .6rem;
  font-family: Outfit-Bold;
}

.common-table tbody tr td a:hover {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  color: white;
}

.common-table .table_icon {
  height: 1.5vw;
}

.common-table tr td span {
  display: inline-block;
  font-size: .9rem;
  line-height: 0;
  height: fit-content;
  font-family: Outfit-Bold;
}

.common-table td .profile_cont {
  text-align: left;
  width: fit-content;
}

.common-table td .profile_cont .profile-name_cont p {
  font-size: var(--font_small);
  color: black;
  font-family: Outfit-SemiBold;
}

.common-table thead tr th {
  border: initial;
  padding: 0 1.6rem;
  font-size: .6rem;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}

.common-table tr td {
  padding: 1.4rem 1.6rem !important;
  border: .1rem solid var(--gray-color) !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  vertical-align: middle;
  font-size: .7rem;
  text-align: center;
}

.common-table tr td b {
  font-family: Outfit-Bold;
}

.common-table tr td:first-child {
  border-top-left-radius: .5rem;
  border-left-color: var(--gray-color) !important;
  border-bottom-left-radius: .5rem;
}

.common-table tr td:last-child {
  border-top-right-radius: .5rem;
  border-right-color: var(--gray-color) !important;
  border-bottom-right-radius: .5rem;
}

.common-table td button {
  padding: .2rem .8rem;
  border-radius: 100px;
  border: .1rem solid var(--gray-color);
  background-color: transparent;
  transition: all .3s;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: .6rem;
  font-family: Outfit-Bold;
}

.common-table td button:hover {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  color: white;
}

.common-table td button:hover a {
  color: white !important;
}

.common-table td button a {
  font-family: Outfit-Bold;
  transition: all .3s;
}

.right-sec_cont {
  padding: .5rem 1.5rem;
  border-radius: 1.4rem;
  border: .2rem solid var(--gray-color);
}

/* Filter Container Css */
.filter_cont {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}

/* Accordian Style Start */

.accordion-button {
  background-color: var(--gray-color) !important;
  font-family: Outfit-SemiBold;
  font-size: .9rem !important;
}

.accordion-item {
  margin-bottom: .6rem;
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
}

.accordion-button:focus {
  border-color: var(--primary_color) !important;
}

.allert_img_cont {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.allert_img_cont h1 {
  font-size: 1.8rem;
  text-align: center;
  margin: 1rem 0;
  font-family: Outfit-SemiBold;
}

.allert_img_cont h2 {
  font-size: 1.3rem;
  text-align: center;
  margin: 1rem 0;
  font-family: Outfit-SemiBold;
}

.allert_img_cont p {
  text-align: center;
  color: rgb(75, 75, 75);
}

.allert_img_cont img {
  height: 7vw;
}

/* ============ Pagination Css Start ============== */
.pagination_cont {
  display: flex;
  justify-content: flex-end;
}

.rc-pagination {
  padding: 0.3rem .4rem !important;
  border-radius: 100px;
  border: .1rem solid rgb(212, 212, 212);
  display: flex;
  align-items: center;
}

.rc-pagination li {
  margin-right: 1rem;
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s;
}

.rc-pagination li:last-child {
  margin-right: 0;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  border-radius: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 1.4rem !important;
}

.rc-pagination li:hover button {
  border: transparent !important;
  color: black !important;
}

.rc-pagination li:hover {
  border: .1rem solid var(--primary_color);
}

.rc-pagination li a{
  font-size: 1rem;
}

.rc-pagination-item-active {
  background-color: var(--primary_color) !important;
  border-color: var(--primary_color) !important;
}

.rc-pagination-item-active a {
  color: white !important;
}

/* ============ Pagination Css End ============== */

.place_holder_img_cont{
  height: 2.5rem;
  width: 2.5rem;
  margin-bottom: 2rem;
}

.place_holder_img_cont img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}