.not-found {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
  }
  
  .not-found h1.display-1 {
    color: var( --primary_color) !important;
  }
  
  .not-found h2.display-4 {
    color: #333;
  }
  
  .not-found p.lead {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: gray;
  }
  
  .not-found .btn-lg {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .not-found .btn-lg:hover {
    background-color: #c82333;
    border-color: #c82333;
  }

  .offline-button {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    border-radius: 30px;
    padding: .5rem 1.2rem;
    margin-top: 1.2rem;
    transition: all 0.3s ease;
    margin: 0 auto;
  }