#induction .filter_cont{
  justify-content: flex-end;
}

#induction .filter_cont button{
   margin-left: 1rem;
}

.documents_table tr td img{
   margin-right: 2rem;
}

#induction .your_induction{
  display: flex;
  max-width: 45%;
  align-items: flex-start;
  padding-left: 1.5rem;
  padding-top: 2rem;
}

#induction .your_induction img{
   margin-right: 2rem;
}

#induction .your_induction p{
   font-size: var(--font_small);
   margin-bottom: 0;
   font-family: Outfit-Medium;
}

#induction .documents_table  tbody tr{
   cursor: initial !important;
}