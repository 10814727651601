.site_icon{
    margin-right: 3.5rem;
}

.site_icon img{
    height: 4rem;
}

#site-details .cont {
    margin-left: 2rem;
    margin-top: 3rem;
}

#site-details .cont p{
    font-size: var( --font_ex_small);
    margin: 2rem 0;
}

#site-details .cont p b{
    margin-right: 2rem;
}

#site-details .filter_cont{
    margin-top: 1rem;
    margin-bottom: 0;
}
