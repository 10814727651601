.timesheet_details_cont {
    max-width: 100%;
    margin: 0 auto;
}

.master_timesheet_filter_container{
    display: flex;
    column-gap: 2rem;
}

.timesheet_details_filter_cont {
    justify-content: flex-end;
}

.timesheet_details_wraper {
    max-width: 100%;
    margin: 0 auto;
}

.timesheet_details tr {
    text-align: center;
    border-top: none;
}

.timesheet_details thead tr {
    border: none;
}

.timesheet_details thead tr th {
    font-size: .7rem;
    padding-bottom: 1.5rem;
    border: none;
    text-transform: uppercase;
}

.timesheet_details tbody tr td {
    font-size: .7rem;
    border: none;
    padding: .2rem;
    vertical-align: middle;
}

.timesheet_details tbody tr td input {
    border: 0;
    width: 100%;
    outline: none;
    text-align: center;
    background: transparent;
}

.timesheet_details tbody tr td input[type=number]::-webkit-inner-spin-button, 
.timesheet_details tbody tr td input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/* .timesheet_details tbody tr td input {
    border: 0;
    width: 100%;
    outline: none;
    text-align: center;
    background: transparent;
} */

.timesheet_details tbody tr td.bordered {
    border: 1px solid black;
}

.reset_timesheet_filter_btn {
    font-size: 1rem;
    padding: .2rem .8rem;
    border-radius: 1rem;
    border: 1px solid rgb(140, 140, 140);
    color: white;
    background: red;
    width: 25%;
    margin: 0 auto;
}

.timesheet_details tbody tr td .approve_timesheet_btn {
    font-size: .5rem;
    padding: .2rem .8rem;
    border-radius: 1rem;
    border: 1px solid rgb(140, 140, 140);
    width: 100%;
}

.selected_timesheet{
    /* background: var(--primary_color); */
    background: red;
}

.timesheet_details tbody tr td .approve_timesheet_btn img {
    margin-right: .2rem;
}

.timesheet_details tbody tr.site_name {
    background: var(--gray-dark);
}

.timesheet_details tbody tr.total_row {
    background: var(--primary_color);
}

.red-border1 {
    border: 1px solid red !important;
}

.red-txt1 {
    color: red;
}

.download_measure_btn{
    padding: .3rem .5rem;
    margin: 0 auto;
    font-size: .5rem;
    border-radius: .5rem;
    border: 1px solid black;
    background: white;
}

.master_timesheet_footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    overflow: scroll;
}

.master_timesheet_footer ul {
    font-size: .5rem;
    color: var(--primary_color);
}

.master_timesheet_footer ul li {
    margin-top: .4rem;
}

.approve_timesheet_btn {
    font-size: .8rem;
    padding: .5rem 3rem;
    border-radius: 1rem;
    border: 1px solid rgb(140, 140, 140);
    height: fit-content;
    margin: 0 auto;
}

.approve_timesheet_btn img {
    margin-right: 1rem;
}

.calculations {
    font-size: .6rem;
}

.calculations div {
    display: flex;
    justify-content: space-between;
}

.calculations div p:first-of-type {
    width: 15rem;
}

.calculations div p:not(:first-of-type) {
    width: 5rem;
}

.comment_row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary_color);
}

.comment_row span:first-child{
    min-width: 7rem;
}