.search_cont {
    display: flex;
    align-items: center;
    border-radius: .5rem;
    overflow: hidden;
    border: 1px solid #ced4da !important;
    padding: 0rem 0;
    padding-left: .2rem;
    height: 2rem;
    transition: all .4s;
    position: relative;
}

.search_cont.focused{
    border: 1px solid var(--primary_color) !important;
}

.search_cont i{
    margin-right: .5rem;
    padding-left: .5rem ;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: .7rem;
}

.search_cont i:last-child{
  font-size: .9rem;
}

.search_cont input{
    font-size: .7rem;
    height: 100%;
    padding: 0 1rem !important;
    border: initial !important;
    border-radius: initial;
    width: 87%;
    outline: none;
}

.search_cont input::-webkit-calendar-picker-indicator{
    cursor: pointer;
    width: 100%;
    position: absolute;
    right: 0;
    height: 100%;
    font-size: .2rem;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
}