

#add-subcontractor h2{
    font-size: var(--font_para);
    font-family:  Outfit-Bold;
    margin-bottom: 3rem;
}

.first_last_name_inp{
    width: 30vw;
    display: flex;
    justify-content: space-between;
}