.offline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Roboto', sans-serif;
    padding: 0 8rem;
  }

  .offline-container  .text_cont{
    width: 50%;
  }
  
  .offline-text {
    font-size: 3rem;
    font-weight: bold;
  }

  .offline-text span{
    color: var(--primary_color);
    text-decoration: underline;
  }
  
  .offline-icon {
    font-size: 80px;
    color: #6c757d;
  }
  
  .offline-button {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    border-radius: 30px;
    padding: .5rem 1.2rem;
    margin-top: 1.2rem;
    transition: all 0.3s ease;
  }
  
  .offline-button:hover {
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.2);
  }

  .offline-container{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #ffedc9;
    background-image: linear-gradient(180deg, #ffedc9 0%, #ffffff 100%);
    display: flex;
  }

  .offline-container img{
    height: 100%;
    width: 50%;
    object-fit: contain;
  }