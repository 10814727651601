.donat_chart_cont{
    height: 16.6vw;
}

.apexcharts-legend-text{
    font-size: .6rem !important;
    padding-left: 1.3rem !important;
}

.apexcharts-legend-marker{
    height: .8rem !important;
    width: 1.1rem !important;
    margin-bottom: .2rem;
}

.apexcharts-legend{
    justify-content: space-evenly !important;
}

.apexcharts-text{
    font-family: Outfit-Bold !important;
    text-transform: uppercase !important;
}