#site_projects .search_cont{
    width: 70%;
    margin-left: 1rem;
}

#site_projects table td .text_icon p{
  font-size: var(--font_para) !important;
  font-family: Outfit-SemiBold;
  margin: initial;
  text-align: left;
  margin-left: 1.5rem;
}
#site_projects table td .text_icon img{
    height: 2.5rem;
}

#site_projects .common-table tr td{
    padding: .8rem 1rem !important;
}

#site_projects table thead th span{
    display: block;
    width: 10rem;
}