.Loader_cont{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loader_cont img{
    height: 9rem;
    object-fit: contain;
}