.profile_details_cont .header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
}

.profile_details_cont .profile_img_cont {
    height: 8vw;
    width: 8vw;
    background-color: var(--gray-dark);
    border-radius: 100%;
    margin-right: 2rem;
    overflow: hidden;
}

.profile_details_cont .profile_img_cont img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.profile_details_cont .profile_txt .edit_field {
    display: flex;
    align-items: center;
    margin-bottom: .3rem;
}

.profile_details_cont .profile_txt .edit_field button {
    margin-left: 1rem;
    color: var(--primary_color);
    background-color: transparent;
}

.profile_details_cont .profile_txt h3 {
    font-size: var(--font_para) !important;
    font-family: Outfit-Bold;
}

.profile_details_cont .profile_txt h4 {
    font-size: var(--font_para);
    font-family: Outfit-SemiBold;
    margin: initial;
}

.profile_details_cont .profile_txt p {
    margin: initial;
}

.profile_details_cont .profile_txt span {
    padding: .3rem 0;
    margin-right: 2.3rem;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Outfit-SemiBold;
    font-size: .7rem;
    color: var(--gray-dark);
}

.profile_details_cont .profile_txt span.active {
    border-bottom: 4px solid var(--primary_color);
    color: black;
}

#sub_contractor_profile .right-sec_cont {
    padding-bottom: 7rem;
}

.information_cont {
    margin-left: 3rem;
}

#sub_contractor_profile .information_cont h3 {
    font-size: var(--font_para);
    font-family: Outfit-Bold;
}

.information_cont .information_cont_table {
    border-collapse: separate;
    border-spacing: 0 .6rem;
    color: rgb(54, 54, 54);
    margin-bottom: 2rem;
}

.information_cont .information_cont_table td {
    font-size: .8rem;
}

.information_cont .information_cont_table td:first-child {
    padding-right: 49px;
    font-family: Outfit-SemiBold;
}

.input_field_cont {
    padding: 1rem 2rem;
}

#inactive_soletrader_profile .text_cont {
    height: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#inactive_soletrader_profile .text_cont h1{
    font-size: 4rem;
}

#inactive_soletrader_profile .text_cont h3{
    font-size: 1.3rem !important;
}

.empty_data{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.empty_data h3{
    text-transform: capitalize;
}

.comp_by_other{
    background-color: var(--secondary_color);
    padding: .3rem 1rem;
    border-radius: .2rem;
    border-bottom: .1rem solid var( --primary_color);
}

.comp_by_other p{
    font-size: var(--font_small);
    margin: initial;
}

#add-documents{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
}

#add-documents h2{
  font-size: var(--font_para);
  font-family: Outfit-Bold;
  margin-bottom: 3rem;
}

.drag_and_drop_cont{
  height: 20vw;
}

.drag_and_drop_cont .drop-here{
  position: relative;
  border: 2px dashed var(--primary_color);
  border-radius: 1rem;

}

 .drag_and_drop_cont label {
  border-radius: 17px;
  overflow: hidden;
  border: none;
  height: 17vw;
}

.drag_and_drop_cont label svg, .sc-dmqHEX{
  opacity: 0;
}

.drag_and_drop_cont .drop-here .txt_cont{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag_and_drop_cont .drop-here .txt_cont img{
  margin-bottom: 2rem;
}

.drag_and_drop_cont .drop-here .txt_cont p{
  font-size: 1.1rem;
  font-family: Outfit-Regular;
  margin-bottom: .3rem;
  color: rgb(78, 78, 78);
}

.drag_and_drop_cont .drop-here .txt_cont .browse{
  color: var(--primary_color);
}

.drag_and_drop_cont .drop-here .txt_cont .file_type{
  font-size: var(--font_ex_small);
  margin-top: .8rem;
}

.drop-here .txt_cont .file_type span{
  margin-left: .4rem;
}

.form_input_video input{
  height: 17vw;
  display: initial !important;
  position: relative;
  z-index: 1;
  opacity: 0;
}