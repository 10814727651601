.form_input_cont{
  margin-bottom: 1.5rem;
}
.form_input_cont p{
  margin-bottom: .5rem;
  font-size: var(--font_small);
} 

.form_input_cont .input{
    padding: 1.1rem;
    border-radius: 100px;
    border: 1px solid var(--gray-color);
    outline: none;
    font-size: var(--font_small);
    width: 100%;
}

.red-border{
  border: 1px solid red !important;
}
.red-txt{
   color: red;
}