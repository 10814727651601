.sub-contractor_table tr td{
    padding: .5rem 1.5rem !important;
    max-width: 11vw;
}
.sub-contractor_table tr th{
    padding: .5rem  1.5rem !important;
}

.sub-contractor_table td .profile_cont .profile-img{
    height: 3rem;
    width: 3.5rem;
    background-color: var(--gray-color);
  }
  .link{
    color: blue !important;
    /* text-decoration: underline !important; */
    border: none !important;
  }