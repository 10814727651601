#add-documents{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
}

#add-documents h2{
  font-size: var(--font_para);
  font-family: Outfit-Bold;
  margin-bottom: 3rem;
}

.drag_and_drop_cont{
  height: 20vw;
}

.drag_and_drop_cont .drop-here{
  position: relative;
  border: 2px dashed var(--primary_color);
  border-radius: 1rem;

}

 .drag_and_drop_cont label {
  border-radius: 17px;
  overflow: hidden;
  border: none;
  height: 17vw;
}

.drag_and_drop_cont label svg, .sc-dmqHEX{
  opacity: 0;
}

.drag_and_drop_cont .drop-here .txt_cont{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag_and_drop_cont .drop-here .txt_cont img{
  margin-bottom: 2rem;
}

.drag_and_drop_cont .drop-here .txt_cont p{
  font-size: 1.1rem;
  font-family: Outfit-Regular;
  margin-bottom: .3rem;
  color: rgb(78, 78, 78);
}

.drag_and_drop_cont .drop-here .txt_cont .browse{
  color: var(--primary_color);
}

.drag_and_drop_cont .drop-here .txt_cont .file_type{
  font-size: var(--font_ex_small);
  margin-top: .8rem;
}

.drop-here .txt_cont .file_type span{
  margin-left: .4rem;
}

.form_input_video input{
  height: 17vw;
  display: initial !important;
  position: relative;
  z-index: 1;
  opacity: 0;
}