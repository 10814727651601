.custom_dropdown {
  min-width: 14vw;
  background-color: var(--green_color);
  border-radius: var(--border_radius);
  margin-bottom: 1.5rem;
}

.sec-center {
  position: relative;
  width: 100%;
  z-index: 200;
}

.sec-center p {
  margin-bottom: 0.5rem;
  font-size: var(--font_small);
}

.custom_dropdown .dropdown-btn {
  padding: 1.1rem;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid var(--gray-color);
  outline: none;
  font-size: var(--font_small);
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom_dropdown .dropdown-btn .uil {
  margin-left: 30px;
  transition: all 200ms linear;
}

.custom_dropdown.open .uil {
  transform: rotate(180deg);
  margin-left: 30px;
  transition: transform 200ms linear;
}

.section-dropdown {
  position: absolute;
  padding: .5rem;
  top: 6vw;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgb(9 9 12 / 21%);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
  background-color: white;
}

.section-dropdown.openTop {
  top: -15vw;
}

.section-dropdown .link_cont {
  max-height: 13vw;
  overflow: auto;
}

.section-dropdown .link_cont .options {
  width: 100%;
  font-size: var(--font_small);
}

.custom_dropdown.open .section-dropdown {
  opacity: 1;
  min-height: 16vw;
  pointer-events: auto;
  transform: translateY(0);
  background-color: white;
}

.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--primary-fade_color);
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.dark-light:checked~.sec-center .section-dropdown:after {
  border-bottom: 8px solid #fff;
}

.custom_dropdown .options {
  padding: .5rem 1rem;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  transition: all .5s;
  border-radius: var(--border_radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  background-color: var(--secondary_color);
  margin-top: .5rem;
}


.custom_dropdown .options div {
  cursor: pointer;
  color: white;
  background-color: var(--primary_color);
  padding: .1rem .8rem;
  border-radius: 100px;
  font-size: .8rem;
}

.custom_dropdown .options .selected_option {
  background-color: gray;
}