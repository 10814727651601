.overview_cont {
    padding-bottom: 2vw;
}

.pending_time_sheet_empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15vw;
}

.pending_time_sheet_empty h2{
    font-size: 1rem;
}

.overview_cont .view_all_btn{
   background-color: var(--primary_color);
   font-size: var(--font_ex_small);
   color: white;
   padding: .2rem 1rem; 
   border-radius: .3rem;
   margin: 0 auto;
}

.overview_cont .cont {
    width: 87%;
    margin: 0 auto;
}

.chart_cont {
    display: flex;
    width: 100%;
    margin-bottom: .7rem;
}

.chart {
    width: 43%;
    padding: 1.6rem 1.5rem;
    border-radius: 1.4rem;
    border: .1rem solid var(--gray-color);
    margin-right: 1.4rem;
}

.chart_cont .chart h3 {
    font-size: .55rem !important;
    margin-bottom: 2rem;
    font-family: Outfit-Bold ;
    text-transform: uppercase;
}

.lft_sec,
.rgt_sec {
    width: 43%;
}

.lft_sec{
    padding: .6rem;
}

.lft_sec .pending-timesheet_cont {
    position: relative;
    width: 100%;
    border-radius: 1.4rem;
    border: .1rem solid var(--primary_color);
    height: fit-content;
    padding: 1rem;
}

.floting_elem .circle {
    position: absolute;
    background-color: white;
    top: 7vw;
}

.floting_elem .c1 {
    left: -.1rem;
    height: 2.3rem;
    width: 1.1rem;
    border-radius: 0 10rem 10rem 0;
    border: .1rem solid var(--primary_color);
    border-left-color: white;
}

.floting_elem .c2 {
    right: -.1rem;
    height: 2.3rem;
    width: 1.1rem;
    border-radius: 10rem 0 0 10rem;
    border: .1rem solid var(--primary_color);
    border-right-color: white;
}

.pending-timesheet_cont .header_cont {
    width: 100%;
    height: 10vw;
    border-bottom: 2px dashed var(--gray-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.pending-timesheet_cont .header_cont .img_cont img{
   height: 3.5rem;
}

.pending-timesheet_cont .header_cont p {
    margin-top: 2rem;
    font-size: var(--font_small);
    font-family: Outfit-Bold;
}

.pending-timesheet_cont .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 59%;
}

.pending-timesheet_cont .site_cont {
    display: flex;
    align-items: flex-start;
    margin: 2rem 0;
}

.pending-timesheet_cont .site_cont .site_logo {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1rem;
    background-color: var(--primary_color);
    margin-right: 1.4rem;
}

.pending-timesheet_cont .text_cont p {
    font-size: .8rem;
}

.pending-timesheet_cont .text_cont p.payroll_date {
    color: var(--gray-dark);
    margin: initial;
}

.pending-timesheet_cont .text_cont p.date {
    color: rgb(65, 65, 65);
    font-family: Outfit-Medium;
    margin: initial;
}

.pending-timesheet_cont .amount-transfer_cont {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.pending-timesheet_cont .discription {
    margin-top: 2rem;
}

.pending-timesheet_cont .discription p {
    text-align: center;
    margin-bottom: 1rem;
}

.btn_cont .see-timesheet_btn {
    padding: 0.1vw 0.3vw;
    border-radius: .4rem;
    font-size: var(--font_para);
    margin: initial;
    /* margin-top: 1rem; */
}

.expiring-tickets_list {
    list-style: none;
    padding: initial;
}

.expiring-tickets_list li {
    display: flex;
    padding: .5rem 1rem;
    border-radius: 1rem;
    border: 1px solid var(--gray-color);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.expiring-tickets_list li p {
    font-size: .8rem;
    margin: initial;
}

.expiring-tickets_list li .profile_cont {
    max-width: 50%;
}

.expiring-tickets_list li .expiery_cont {
    min-width: 30%;
    max-width: 40%;
}
  .btnTextColor{
    color: #EB742D;
    font-family: Outfit-Medium;
    margin: initial;
  }
  .statusTextColor{
    color: #FF0C0D;
    font-family: Outfit-Medium;
    margin: initial;
  }