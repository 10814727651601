.dropdown_cont{
    width: 9vw;
    display: flex;
    align-items: center;
}

.dropdown_cont label{
  margin-right: 1rem;
  font-size: .6rem;
  font-family: Outfit-Bold;
  text-transform: uppercase;
}

.dropdown_cont .btn_dropdown_wraper{
  width: 80%;
  position: relative;
}

.dropdown_cont .dropdown_btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: .1rem solid #ced4da;
  border-radius: .4rem;
  padding: .4rem 1rem;
  font-size: .7rem;
}

.dropdown_cont .dropdown_btn i{
  color: rgb(123, 123, 123);
  transition: all .2s linear;
}

.dropdown_cont .dropdown_options{
  position: absolute;
  padding: 1rem .5rem;
  width: 100%;
  background-color: white;
  box-shadow: 0 .5rem 1.5rem 0 rgb(9 9 12 / 13%);
  border-radius: .5rem;
  top: 120%;
  max-height: 18vw;
  overflow-y: auto;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}

.dropdown_cont .btn_dropdown_wraper.open .dropdown_options{
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.dropdown_cont .btn_dropdown_wraper.open i{
  transform: rotate(180deg);
}

.dropdown_cont .dropdown_options p{
  border-radius: .3rem;
  padding: .2rem .5rem;
  background-color: var(--secondary_color);
  margin-bottom: .5rem;
  font-size: var(--font_ex_small);
  transition: all .5s;
  cursor: pointer;
}

.dropdown_cont .dropdown_options p:hover{
  background-color: var(--primary_color);
  color: white;
}

.dropdown_cont .dropdown_options p:last-child{
  margin: initial;
}