.training_martix_table tr td, .training_martix_table tr th{
  font-size: .7rem;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.training_martix_table tr th{
   text-transform: uppercase;
   font-size: .6rem;
}

.training_martix_table tr td{
  padding: .6rem;
}

.training_martix_table tr td .tickets-date{
    margin: initial;
    padding: .5rem;
    border-radius: 100px;
    min-width: 9vw;
}

.training_martix_table tr td .tickets-date.expire_soon{
    background-color: #FECA57;
    font-family: Outfit-Bold;
}
.training_martix_table tr td .tickets-date.expired{
    background-color: #F80D38;
    color: white;
    font-family: Outfit-Bold;
}
.training_martix_table tr td .tickets-date.valid{
    background-color: transparent;
    font-family: Outfit-Bold;
}
.training_martix_table tr td .tickets-date.not-provided{
    background-color: white;
    padding: 1rem 0;
    /* color: white; */
    font-family: Outfit-Bold;
}

.training_martix_table tr{
  vertical-align: middle;
}

.training_martix_table tr:first-child th:first-child{
   border-top-left-radius: 10px !important;
   border-left-color: var(--gray-color) !important ;
}

.indicators_cont{
  display: flex;
  align-items: center;
}

.indicators_cont .indicators{
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.indicators span{
  display: inline-block;
   height: .8rem;
   width: .8rem;
   background-color: #FECA57;
   border-radius: 100%;
}

.indicators.i-1 span{
  background-color: #F80D38;
}
.indicators.i-2 span{
  background-color: #FECA57;
}
.indicators.i-3 span{
  background-color: #343434;
}

.indicators_cont .indicators p{
  margin: initial;
  font-size: var(--font_ex_small);
  margin-left: 1rem;
}

.training_matrix_table_cont{
  overflow: auto;
}

.add-training-input{
  border: 1px solid var(--gray-color);
  padding: 1rem 0.5rem;
  font-size: 1rem;
  height: 2rem;
  outline: none;
}

.edit_module_row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-color);
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1rem;
}

.edit_module_row h1{
  font-size: 1rem;
  margin: 0;
  width: 25%;
}

.edit_module_row button{
  color: white;
  background-color: #F80D38;
  padding: .1rem .8rem;
  border-radius: 100px;
  font-size: .8rem;
  padding: 0.6rem 2rem;
} 

.edit_module_row button:first-of-type{
  color: white;
  background-color: var( --primary_color);
  padding: .1rem .8rem;
  border-radius: 100px;
  font-size: .8rem;
  padding: 0.6rem 2rem;
}
.fixTableHead { 
  overflow-y: auto; 
  height: fit-content;
} 
.fixTableHead thead th { 
  position: sticky; 
  top: 0; 
  min-width: 10rem;
} 
table { 
  border-collapse: collapse;
  width: 100%; 
} 
th, 
td { 
  padding: 8px 15px; 
  border: 2px solid #529432; 
} 
th { 
  background: #ABDD93; 
} 