.nav-bar_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.7rem 1rem;
    padding-left: 1.7rem;
    background-color: var(--secondary_color);
    border-radius: 1.4rem;
}

.nav-bar_cont .text_cont {
    display: flex;
    align-items: center;
    padding: .5rem 1.2rem;
    padding-left: 0;
}


.nav-bar_cont .text_cont .notification_btn {
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 100%;
    background-color: white;
    border: 1px solid var(--gray-dark);
    margin-left: 2.5rem;
}

.nav-bar_cont .text_cont .notification_btn img {
    height: 1.1rem;
}

.nav-bar_cont .text_cont img {
    height: 1.3rem;
}

.nav-bar_cont .text_cont p {
    font-size: .8rem;
    margin: initial;
    margin-left: .7rem;
    font-family: Outfit-Bold;
    word-break: break-all;
}

.nav-bar_cont .profile_cont {
    width: 15%;
    margin-left: 1%;
}

.nav-bar_cont .profile_cont .profile-name_cont p {
    font-size: .8rem;
    line-height: 1rem;
}

.nav-bar_cont .profile_cont .profile-name_cont p:first-child {
    font-family: Outfit-Bold;
    font-size: var(--font_small);
}


/* ================ Notification Css Start ==================== */
.notification_cont {
    position: relative;
}

.notification_cont .notification_wraper {
    right: 1.5rem;
    top: 2rem;
    height: fit-content;
    width: 30vw;
    position: absolute;
    background-color: white;
    border-radius: 1rem;
    border-top-right-radius: 0;
    box-shadow: 0 14px 35px 0 rgb(9 9 12 / 21%);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
}

.notification_cont .notification_btn {
    position: relative;
}

.notification_cont .notification_btn .badge{
    border-radius: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -.8rem;
    right: -.8rem;
   height: 1.3rem;
   width: 1.3rem;
   background-color: rgb(218, 18, 18);
   color: white;
   font-family: Outfit-Bold;
}

.notification_cont .notification_wraper.show{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

.notification_cont .notification_wraper .header {
    border-bottom: .1rem solid var(--gray-dark);
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    height: 4vw;
}

.notification_wraper .header h2 {
    font-size: var(--font_sub_heading);
    margin: initial;
    font-family: Outfit-SemiBold;
    color: var(--primary_color);
}

.notification_body {
    width: 100%;
    height: 30vw;
    padding: 1rem 1.5rem;
    overflow-y: auto;
}

.notification_body .notify_card {
    height: fit-content;
    width: 100%;
    background-color: white;
    border-radius: .6rem;
    overflow: hidden;
    box-shadow: 0 .2rem .3rem rgba(0, 0, 0, 0.121);
    margin-bottom: 1rem;
    cursor: pointer;
}

.notification_body .notify_card .top {
    padding: .8rem 1.3rem;
    background-color: var(--secondary_color);
}

.notification_body .notify_card .top h3 {
    margin: initial;
    font-size: var(--font_small) !important;
}

.notification_body .notify_card .btm {
    padding: .8rem 1.5rem;
}

.notification_body .notify_card .btm p {
    margin-left: 0;
}

.notify_card .btm .msg {
    font-family: Outfit-Regular;
}

.notification_body .notify_card .time i {
    margin-right: .5rem;
    color: var(--gray-dark);
}

.notification_cont .notification_wraper .pagination_cont{
    padding: .5rem 0;
}

.notification_cont .notification_wraper .clame_type{
   background-color: var(--primary_color);
   color: white;
   padding: .2rem .5rem;
   border-radius: 1rem;
   width: fit-content;
   font-size: .6rem !important;
}

.notification_cont .notification_wraper .sole-trader{
   font-size: .9rem !important;
   font-family: Outfit-Bold !important;
}

/* ================ Notification Css End ==================== */
