:root {
  --white-color: #FFFFFF;
  --primary_color: #EB742E;
  --secondary_color: #F7F7F7;
  --gray-color: #DFE0E2;
  --gray-dark: #b5b7ba;
  --green-color: #C6FF53;

  --font_heading: 1.5rem;
  --font_sub_heading: 1.2rem;
  --font_para: 1rem;
  --font_small: .9rem;
  --font_ex_small: .88rem;
}

@font-face {
  font-family: Outfit-Regular;
  src: url(./assets/fonts/Outfit-Regular.ttf);
}

@font-face {
  font-family: Outfit-Bold;
  src: url(./assets/fonts/Outfit-Bold.ttf);
}

@font-face {
  font-family: Outfit-Medium;
  src: url(./assets/fonts/Outfit-Medium.ttf);
}

@font-face {
  font-family: Outfit-SemiBold;
  src: url(./assets/fonts/Outfit-SemiBold.ttf);
}

/* Smaller devices such as mobile phones */
@media (max-width: 600px) {
  /* Adjust font size for smaller devices */
  html {
    font-size: 0.875rem;
  }

}

/* Tablets and larger smartphones */
@media (max-width: 950px) {
  html {
    font-size: 0.938rem !important;
  }

}

/* Small laptops and larger tablets */
@media (max-width: 1024px) {
  html {
    font-size: 1vw !important;
  }

}