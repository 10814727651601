nav{
    background-color: var(--secondary_color);
    /* background-color: brown; */
    height: 100%;
    width: 100%;
    border-radius: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

nav .logo_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vw;
    border-bottom: 2px solid var(--gray-color);
}

nav .logo_cont img{
   width: 8rem;
}

nav .nav-link_cont{
    display: flex;
    flex-direction: column;
    padding: 2.4rem 0;
}

nav .nav-link_cont a:first-child{
    margin: initial;
}

nav .nav-link_cont a{
    position: relative;
    padding: 1.15rem 2.3rem;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all .5s;
   font-size: var(--font_ex_small);
   margin-top: 1.5rem;
   color: #0d0d0dba !important;
   font-family: Outfit-Medium;
}

nav .nav-link_cont a.active{
  background-color: var(--gray-color);
  color: var(--primary_color) !important;
  font-family:  Outfit-Bold ;
  /* font-size: var(--font_small); */
}

nav .nav-link_cont a img{
    margin-right: 1.2rem;
    height: 1.2rem;
    transition: all .3s;
}

nav .nav-link_cont a.active img{
    height: 1.3rem;
}

nav .nav-link_cont h3{
    font-size: var(--font_para);
    padding: 2rem 2.3rem;
    font-family:  Outfit-Bold;
}

nav .nav-link_cont a span{
   position: absolute;
   padding: .1rem .4rem;
   border-radius: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #F80D38;
   color: white;
   font-size: .5rem;
   right: 4.5rem;
   font-family:  Outfit-Bold;
}

nav  .contact_us{
    display: flex;
    align-items: center;
    padding: 0 2.3rem;
    height: 7vw;
    width: 100%;
    border-top: 1px solid var( --gray-dark);
    cursor: pointer;
}

nav  .contact_us p{
    color: rgb(111, 111, 111);
}

nav  .contact_us p, nav  .contact_us h3{
    margin: initial;
    text-align: left;
}

nav  .contact_us img{
    width: 2rem;
    margin-right: 1rem;
}
nav .logout_btn{
   cursor: pointer;
}